<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card light>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">
                    {{ form.id ? 'Edição' : 'Cadastro' }} de Novidade
                </span>
                <v-spacer/>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Titulo"
                            v-model="form.titulo"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            label="Descrição"
                            v-model="form.descricao"
                            rows="4"
                            clear-icon="mdi-backspace-outline"
                            clearable
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer/>
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Novidade',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: Object
        },
    },

    data: () => ({
        form: {},
        loading: false,
    }),

    mounted() {
        this.form = this.formData;
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        },
    },

    methods: {
        salvar() {
            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`novidades/${id}`, this.form).then(() => {
                    this.$emit('onCadastro');
                    // this.notify('Atualizado com sucesso!');
                }).catch(e => {
                    alert(e);
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('novidades', this.form).then(() => {
                this.$emit('onCadastro');
                // this.notify('Salvo com sucesso!');
                // this.reset();
            }).catch(e => {
                alert(e);
            }).finally(() => (this.loading = false));
        },
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
